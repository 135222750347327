<template>
  <b-container>
    <loading
      :active.sync="isLoading"
      :color="'#408521'"
      :is-full-page="false"
    ></loading>

    <b-row>
      <b-col md="9">
        <h3>Moji zapisi</h3>
      </b-col>
      <b-col md="3" class="text-right">
        <a
          v-if="posts && posts.length > 0"
          class="btn btn-lg btn-success text-white"
          @click="
            () => {
              $router.push({ name: 'EditPost' });
            }
          "
          ><i class="fa fa-plus"></i> Napiši nov zapis</a
        >
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <div v-if="posts && posts.length === 0" class="my-5">
          Zaenkrat še nimate shranjenih zapisov. Bi želeli napisati novega?

          <div>
            <a
              class="btn btn-primary text-white mt-3"
              @click="
                () => {
                  $router.push({ name: 'EditPost' });
                }
              "
              ><i class="fa fa-plus"></i> Napiši zapis</a
            >
          </div>
        </div>

        <div v-if="posts && posts.length > 0" class="my-5">
          <b-row>
            <b-col md="12" class="text-center mb-4">
              <b-button-group class="ml-auto">
                <b-button
                  :variant="
                    viewFilter == 'temp' ? 'primary' : 'outline-primary'
                  "
                  @click="
                    () => {
                      viewFilter = 'temp';
                    }
                  "
                  >Začasno shranjeni zapisi
                </b-button>
                <b-button
                  :variant="
                    viewFilter == 'submitted' ? 'primary' : 'outline-primary'
                  "
                  @click="
                    () => {
                      viewFilter = 'submitted';
                    }
                  "
                  >Zapisi oddani v pregled
                </b-button>
                <b-button
                  :variant="
                    viewFilter == 'confirmed' ? 'primary' : 'outline-primary'
                  "
                  @click="
                    () => {
                      viewFilter = 'confirmed';
                    }
                  "
                  >Objavljeni zapisi
                </b-button>
              </b-button-group>
            </b-col>
          </b-row>

          <vue-good-table
            :columns="columns"
            :search-options="{
              enabled: true,
              skipDiacritics: true,
              placeholder: 'vnesi iskalni pojem',
              searchFn: searchFunc
            }"
            styleClass="tableOne vgt-table striped"
            :rows="filteredPosts"
          >
            <template slot="table-row" slot-scope="props">
              <div v-if="props.column.field == 'post'">
                <h3>{{ props.row.title }}</h3>
                <p>{{ props.row.summary }}</p>
                <p>
                  <b class="pr-2">Status:</b>
                  <span v-if="!props.row.submitted">začasno shranjen</span>
                  <span v-if="props.row.submitted && !props.row.confirmed"
                    >oddan v preverjanje</span
                  >
                  <span v-if="props.row.confirmed">potrjen in objavljen</span>
                </p>
                <p>
                  Akcije:<br />
                  <a
                    class="btn btn-sm btn-secondary text-white text-uppercase mx-1"
                    @click.stop="showPost(props.row.id)"
                    ><i class="fa fa-eye"></i> Poglej</a
                  >
                  <a
                    v-if="!props.row.submitted && !props.row.confirmed"
                    class="btn btn-sm btn-info text-white text-uppercase mx-1"
                    @click.stop="editPost(props.row.id)"
                    ><i class="fa fa-edit"></i> Uredi</a
                  >
                </p>
              </div>
              <div v-if="props.column.field == 'summary'">
                {{ props.row.summary }}
              </div>
              <div v-if="props.column.field == 'status'">
                <span v-if="!props.row.submitted">začasno shranjen</span>
                <span v-if="props.row.submitted && !props.row.confirmed"
                  >oddan v preverjanje</span
                >
                <span v-if="props.row.confirmed">potrjen in objavljen</span>
              </div>
              <div v-if="props.column.field == 'actions'">
                <a
                  class="btn btn-sm btn-secondary text-white text-uppercase mx-1"
                  @click.stop="showPost(props.row.id)"
                  ><i class="fa fa-eye"></i> Poglej</a
                >
                <a
                  class="btn btn-sm btn-info text-white text-uppercase mx-1"
                  @click.stop="editPost(props.row.id)"
                  ><i class="fa fa-edit"></i> Uredi</a
                >
              </div>
            </template>
          </vue-good-table>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
// @ is an alias to /src
// import firebase from "firebase";
// import {db} from "../../main";
import Loading from "vue-loading-overlay";
import { db } from "@/main";
import _ from "lodash";

export default {
  name: "MyPosts",
  components: {
    Loading
  },
  data() {
    return {
      posts: null,
      columns: [
        {
          label: "Zapis",
          field: "post"
        }
      ],
      viewFilter: "temp",
      isLoading: false
    };
  },
  computed: {
    filteredPosts() {
      const _this = this;
      //.where("submitted", "==", _this.viewFilter === "temp" ? false : true)
      return _.filter(_this.posts, {
        submitted: _this.viewFilter === "temp" ? false : true,
        confirmed: _this.viewFilter === "confirmed" ? true : false
      });
    }
  },
  mounted() {
    const _this = this;

    _this.retrievePosts();
  },
  methods: {
    retrievePosts() {
      const _this = this;

      _this.isLoading = true;

      _this.post = db
        .collection("posts")
        .where("createdBy", "==", _this.$store.getters.getUser.id)
        .where("deleted", "==", false)
        .get()
        .then(function(_docs) {
          _this.posts = _docs.docs.map(doc => doc.data());
          _this.isLoading = false;
        })
        .catch(error => {
          console.log("Error getting categories:", error);
          _this.isLoading = false;
        });
    },

    showPost(postItem) {
      const _this = this;
      _this.$router.push({
        name: "PreviewPost",
        params: { postId: postItem }
      });
    },

    editPost(postItem) {
      const _this = this;
      _this.$router.push({ name: "EditPost", params: { postId: postItem } });
    },

    searchFunc(row, col, cellValue, searchTerm) {
      const _this = this;
      if (
        row.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        row.summary.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (row.content
          ? row.content.toLowerCase().includes(searchTerm.toLowerCase())
          : false) ||
        _this.searchFoUser(
          _this.$globalFunctions.getUserById(row.createdBy),
          searchTerm
        )
      ) {
        return true;
      }
    },

    searchFoUser(user, query) {
      return (
        JSON.stringify(user)
          .toLowerCase()
          .indexOf(query.toLowerCase()) > -1
      );
    }
  }
};
</script>
